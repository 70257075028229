/// A debugging utility, not to be used in type definitions.
// export type _Prettify<T> = { [K in keyof T]: T[K] } & {}

export type EmptyObject = Record<never, never>

export type Override<T, R> = Omit<T, keyof R> & R

// TODO: Use Template Literal Types instead on TS >= 4.1:
// export type ValueOf<T> = `$[T]`
export type ValueOf<T> = T[keyof T]

export type RequiredKeys<T, K extends keyof T> = T & Required<Pick<T, K>>

export type MaybePromise<T> = T | Promise<T>

export type JsonPrimitive = string | number | boolean | null
export type JsonObject = Partial<{ [key: string]: JsonValue }>
export type JsonArray = Array<JsonValue>
export type JsonValue = JsonPrimitive | JsonObject | JsonArray

/**
 * These are the "well-kinded" falsy values, which are commonly ignored in
 * rendering, like in React.
 *
 * It is intentional to not include `0` and `""` in this list, as those values
 * might better be checked more explicitly.
 */
export type ReactFalsy = undefined | null | false

export type AnyFalsy = undefined | null | false | 0 | ""

export type Truthy<T> = Exclude<T, AnyFalsy>

export function filterTruthy<T>(values: T[]): Truthy<T>[] {
  return values.filter((value): value is Truthy<T> => Boolean(value))
}

export function filterNonNullish<T>(values: T[]): NonNullable<T>[] {
  return values.filter((value): value is NonNullable<T> => value != null)
}

export function isDate(value: any): value is Date {
  return Object.prototype.toString.call(value) === "[object Date]"
}

export function isValueOfEnum<V, E extends Record<any, any>>(
  enumType: E,
  value: V | null | undefined,
): value is V {
  if (!value) {
    return false
  }
  return Object.values(enumType).includes(value)
}
